import useTagParams from "hooks/useTagParams";
import { TFunction } from "i18next";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ERROR_TYPES } from "types/errors";

interface ServerError {
  errors: {
    [key: string]: string[];
  };
}

export const parseErrors = (err: ServerError) => {
  let res = "";

  Object.keys(err.errors).forEach((key) => {
    res += `${key} ${err.errors[key]}. `;
  });

  return res;
};

export class NetworkRequestError extends Error {
  error: ServerError;
  status?: number;

  constructor(error: ServerError, status?: number) {
    const message = parseErrors(error);
    super(message);
    this.error = error;
    this.status = status;
  }
}

export const errorTypeToMeaning = (errorType: ERROR_TYPES, t: TFunction) => {
  switch (errorType) {
    case ERROR_TYPES.CMAC_ALREADY_USED:
      return t("error.page.meta.error-type.link-used", "Link Already Used");
    case ERROR_TYPES.CMAC_INVALID:
      return t("error.page.meta.error-type.link-invalid", "Link Invalid");
    case ERROR_TYPES.NOT_SHAREABLE:
      return t(
        "error.page.meta.error-type.tag-not-shareable",
        "Tag Not Shareable",
      );
    case ERROR_TYPES.INACTIVE_TAG:
      return t("error.page.meta.error-type.inactive-tag", "Inactive Tag");
    case ERROR_TYPES.TAG_NOT_DETECTED:
      return t(
        "error.page.meta.error-type.tag-not-detected",
        "Tag Not Detected",
      );
    case ERROR_TYPES.UNKNOWN:
      return t("error.page.meta.error-type.generic", "Unknown Error");
  }
};

// This is the way react-router recommends using navigate
// It has the downside of separating the logic from the error handling.
export const useVerificationErrorHandler = (
  e: Error | null,
  shareable = false,
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { uid, cmac, ctr } = useTagParams();

  React.useEffect(() => {
    if (e) {
      if (!shareable && (!uid || !cmac || !ctr)) {
        searchParams.append("err", ERROR_TYPES.TAG_NOT_DETECTED);
        navigate(`/error?${searchParams.toString()}`, { replace: true });
      } else {
        if (e instanceof NetworkRequestError) {
          if (shareable) {
            searchParams.append("err", ERROR_TYPES.INACTIVE_TAG);
            navigate(`/error?${searchParams.toString()}`);
          } else if (e?.error?.errors?.cmac?.indexOf("has expired") !== -1) {
            searchParams.append("err", ERROR_TYPES.CMAC_ALREADY_USED);
            navigate(`/error?${searchParams.toString()}`, { replace: true });
          } else if (e?.error?.errors?.cmac?.indexOf("is invalid") !== -1) {
            searchParams.append("err", ERROR_TYPES.CMAC_INVALID);
            navigate(`/error?${searchParams.toString()}`, { replace: true });
          } else {
            searchParams.append("err", ERROR_TYPES.INACTIVE_TAG);
            navigate(`/error?${searchParams.toString()}`, { replace: true });
          }
        } else {
          searchParams.append("err", ERROR_TYPES.UNKNOWN);
          navigate(`/error?${searchParams.toString()}`, { replace: true });
        }
      }
    }
  }, [e, shareable, uid, cmac, ctr, navigate, searchParams]);
};
