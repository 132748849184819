import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useTagParams from "hooks/useTagParams";
import { useSearchParams } from "react-router-dom";
import { LoginType, TMagicLinkClaimNFT, TTag } from "types/api";
import { NetworkRequestError } from "utils/error";
import {
  fetchTagHistory,
  fetchTagPublic,
  fetchTagVerification,
  fetchTagVerifications,
} from "./tags";
import { resolveOwnerName } from "./nft";
import useDisplayContext from "hooks/useDisplayContext";
import { cacheTagData, getTagData } from "utils/cache";
import { TagVerificationType } from "utils/tables";

export const useTagVerificationQuery = (searchParams: URLSearchParams) => {
  const { dispatch } = useDisplayContext();
  const { uid, cmac, ctr } = useTagParams();

  return useQuery({
    queryKey: ["tags", uid, "verification", cmac, ctr],
    queryFn: async () => {
      let tagData = getTagData({ tagId: uid!, cmac: cmac!, ctr: ctr! });

      if (!tagData) {
        const response = await fetchTagVerification(uid!, searchParams);

        if (!response.ok) {
          throw new NetworkRequestError(await response.json(), response.status);
        }

        const tag: TTag = await response.json();

        if (tag.redirect_url) {
          window.location.replace(
            tag.redirect_url + "?" + searchParams.toString(),
          );
          return;
        }

        if (
          !tag.nft_chain_id ||
          !tag.nft_contract_address ||
          !tag.nft_token_id
        ) {
          throw new Error("NFT data is required to display tag.");
        }

        tagData = cacheTagData({ tag, cmac: cmac!, ctr: ctr! });
      }

      if (!tagData.tag.image_url && !tagData.tag.animation_url) {
        throw new Error("Image or animation URL is required to display tag.");
      }

      const claimed = !!tagData.tag.owner_address;

      const magicClaimNftContent = tagData?.tag?.metadata?.data?.find(
        (m) => m.type === "magic_claim_nft",
      ) as TMagicLinkClaimNFT | undefined;

      if (magicClaimNftContent?.options.redirectUrl && claimed) {
        window.location.replace(
          magicClaimNftContent.options.redirectUrl +
            "?" +
            searchParams.toString(),
        );
        return;
      }

      const ownerName = await resolveOwnerName(tagData.tag?.owner_address);

      dispatch({
        // If tag has no authentication required, show claimed state.
        claimed: tagData.tag.login_type === LoginType.OFF || claimed,
        owner: tagData.tag.owner_address ?? undefined,
        ownerName,
      });

      return { tag: tagData.tag };
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false, // this is already not retried because the unknown error is swallowed by the try catch
    // we can't remove the try catch because navigation is directly tied to the error responses
  });
};

export const useTagPublicQuery = () => {
  const [searchParams] = useSearchParams();
  const { dispatch } = useDisplayContext();

  const tagId = searchParams.get("uid");
  const claimed = !!searchParams.get("claimed");

  return useQuery({
    queryKey: ["tags", tagId, "public"],
    queryFn: async () => {
      const response = await fetchTagPublic(tagId!);

      if (!response.ok) {
        throw new NetworkRequestError(await response.json(), response.status);
      }

      const tag: TTag = await response.json();

      dispatch({ claimed: claimed });

      return { tag };
    },
  });
};

export interface TagVerification {
  created_at: number;
  verify_type: TagVerificationType;
  city?: string;
  region?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
}

export interface TagVerifications {
  pagy: {
    count: number;
    pages: number;
  };
  verifications: TagVerification[];
}

export const useTagVerificationsQuery = (page?: number) => {
  const { uid: tagId } = useTagParams();

  return useQuery({
    queryKey: ["tags", tagId, "verifications", page],
    queryFn: async () => {
      const response = await fetchTagVerifications(tagId!, page);

      if (!response.ok) {
        throw new NetworkRequestError(await response.json(), response.status);
      }

      const responseBody: TagVerifications = await response.json();

      return responseBody;
    },
    enabled: !!tagId,
    placeholderData: keepPreviousData,
  });
};

export interface ITransfer {
  id: number;
  tag_id: number;
  sender_address?: string | null;
  receiver_address?: string | null;
  transfer_type: "mint" | "claim" | "recover";
  created_at: string;
  updated_at: string;
}

export interface ITransferHistory {
  pagy: {
    count: number;
    pages: number;
  };
  transfers: Array<ITransfer>;
}

export const useTagHistoryQuery = (page?: number) => {
  const { uid: tagId } = useTagParams();

  return useQuery({
    queryKey: ["tags", tagId, "history", page],
    queryFn: async () => {
      const response = await fetchTagHistory(tagId!, page);

      if (!response.ok) {
        throw new Error("Transfer history cannot be fetched.");
      }

      const responseBody: ITransferHistory = await response.json();
      return responseBody;
    },
    enabled: !!tagId,
    placeholderData: keepPreviousData,
  });
};
