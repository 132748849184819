export const STAGING_LGT_SERVER_URL = "https://dev-metadata-api.legitimate.tech";

export const API_URL = import.meta.env.VITE_LGT_SERVER_URL || STAGING_LGT_SERVER_URL;

export const BUILDER_IO_PUBLIC_API_KEY = "a6378b4031b246ebad5efa7540a24082";

const MAGIC_AUTH_API_KEY_PRODUCTION = "pk_live_E661AD484C78066A";
const MAGIC_AUTH_API_KEY_STAGING = "pk_live_9DFA9BDAD587ACB5";

export const MAGIC_AUTH_API_KEY =
  import.meta.env.VITE_API_STAGE === "production"
    ? MAGIC_AUTH_API_KEY_PRODUCTION
    : MAGIC_AUTH_API_KEY_STAGING;
