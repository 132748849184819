import { lazy } from "react";
import { useSearchParams } from "react-router-dom";
import { useTagVerificationQuery } from "data/queries";
import Loading from "components/Loading";
import { useVerificationErrorHandler } from "utils/error";

const TagContent = lazy(() => import("components/TagContent"));

const VerificationPage = () => {
  const [searchParams] = useSearchParams();
  const { data, isLoading, error } = useTagVerificationQuery(searchParams);
  useVerificationErrorHandler(error);

  // we can't use the useSuspenseQuery because it doesn't allow early returns and
  // requires the use of ErrorBoundary to catch all errors
  // @todo: when React 19 is released, we can use the new `use` hook
  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <TagContent data={data} />
    </>
  );
};

export default VerificationPage;
