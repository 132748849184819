import { post, get } from "utils/api";
import { NETWORK, getEndpointUrlByChainId } from "utils/chains";
import { SIMPLE_HASH_API_KEY, SIMPLE_HASH_API_URL } from "utils/constants";

interface UnlockNFTArgs {
  chainId: NETWORK;
  cmac: string;
  ctr: string;
  uid: string;
}

export const unlockNFT = ({ chainId, cmac, ctr, uid }: UnlockNFTArgs) => {
  return post(getEndpointUrlByChainId(chainId, "nft/unlock"), {
    cmac,
    ctr,
    uuid: uid,
  });
};

export const resolveOwnerName = async (ownerAddress?: string | null) => {
  let ownerName = "Legitimate User";

  try {
    const response = await get(
      getEndpointUrlByChainId(1, `dynamic/user?address=${ownerAddress}`),
    );

    const { user } = await response.json();

    if (user?.firstName && user?.lastName) {
      ownerName = `${user.firstName} ${user.lastName}`;
    } else if (user?.username) {
      ownerName = user.username;
    } else if (user?.email) {
      ownerName = user.email;
    }

    return ownerName;
  } catch (e) {
    return ownerName;
  }
};

export const resolveAddressFromENS = async (ens: string) => {
  const response = await get(
    `${SIMPLE_HASH_API_URL}/api/v0/ens/lookup?ens_names=${ens}`,
    { "X-API-KEY": SIMPLE_HASH_API_KEY },
  );

  const data = await response.json();

  return data[0].address;
};
