export enum NETWORK {
  ETHEREUM = 1,
  ETHEREUM_SEPOLIA = 11155111,
  POLYGON = 137,
  POLYGON_AMOY = 80002,
  AVALANCHE = 43114,
  AVALANCHE_FUJI = 43113,
  ARBITRUM_ONE = 42161,
  ARBITRUM_ONE_SEPOLIA = 421614,
  ARBITRUM_NOVA = 42170,
  BASE = 8453,
  BASE_SEPOLIA = 84532,
  LAMINA1 = 10849,
  LAMINA1_FUJI = 764984,
}

export const CHAIN_ID_TO_SIMPLE_HASH_NETWORK = {
  [NETWORK.ETHEREUM]: "ethereum",
  [NETWORK.ETHEREUM_SEPOLIA]: "ethereum-sepolia",
  [NETWORK.POLYGON]: "polygon",
  [NETWORK.POLYGON_AMOY]: "polygon-amoy",
  [NETWORK.AVALANCHE_FUJI]: "avalanche-fuji",
  [NETWORK.AVALANCHE]: "avalanche",
  [NETWORK.ARBITRUM_ONE]: "arbitrum",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "arbitrum-sepolia",
  [NETWORK.ARBITRUM_NOVA]: "arbitrum-nova",
  [NETWORK.BASE]: "base",
  [NETWORK.BASE_SEPOLIA]: "base-sepolia",
} as Record<NETWORK, string>;

export const TX_BASE_URL_FOR_CHAIN_ID = {
  [NETWORK.ETHEREUM]: "etherscan.io",
  [NETWORK.ETHEREUM_SEPOLIA]: "sepolia.etherscan.io",
  [NETWORK.POLYGON]: "polygonscan.com",
  [NETWORK.POLYGON_AMOY]: "amoy.polygonscan.com",
  [NETWORK.AVALANCHE]: "snowscan.xyz",
  [NETWORK.AVALANCHE_FUJI]: "testnet.snowscan.xyz",
  [NETWORK.ARBITRUM_ONE]: "arbiscan.io",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "sepolia.arbiscan.io",
  [NETWORK.ARBITRUM_NOVA]: "nova.arbiscan.io",
  [NETWORK.BASE]: "basescan.org",
  [NETWORK.BASE_SEPOLIA]: "sepolia.basescan.org",
  [NETWORK.LAMINA1]: "subnets.avax.network/lamina1",
  [NETWORK.LAMINA1_FUJI]: "subnets-test.avax.network/lamina1tes",
};

export const NETWORK_NAME_BY_CHAIN_ID = {
  [NETWORK.ETHEREUM]: "Ethereum",
  [NETWORK.ETHEREUM_SEPOLIA]: "Ethereum Testnet",
  [NETWORK.POLYGON]: "Polygon",
  [NETWORK.POLYGON_AMOY]: "Polygon Testnet",
  [NETWORK.AVALANCHE]: "Avalanche",
  [NETWORK.AVALANCHE_FUJI]: "Avalanche Testnet",
  [NETWORK.ARBITRUM_ONE]: "Arbitrum One",
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: "Arbitrum One Testnet",
  [NETWORK.ARBITRUM_NOVA]: "Arbitrum Nova",
  [NETWORK.BASE]: "Base",
  [NETWORK.BASE_SEPOLIA]: "Base Testnet",
  [NETWORK.LAMINA1]: "Lamina1",
  [NETWORK.LAMINA1_FUJI]: "Lamina1 Testnet",
};

export const TESTNET_CHAIN_IDS = new Set([
  NETWORK.AVALANCHE_FUJI,
  NETWORK.ETHEREUM_SEPOLIA,
  NETWORK.POLYGON_AMOY,
  NETWORK.ARBITRUM_ONE_SEPOLIA,
  NETWORK.LAMINA1_FUJI,
  NETWORK.BASE_SEPOLIA,
]);

export const generateAddressLink = (chainId: NETWORK, address: string) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/address/${address}`;
};

export const generateTxLink = (chainId: NETWORK, transactionHash: string) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/tx/${transactionHash}`;
};

export const generateAssetLink = (
  chainId: NETWORK,
  address: string,
  tokenId: string,
) => {
  return `https://${TX_BASE_URL_FOR_CHAIN_ID[chainId]}/token/${address}?a=${tokenId}`;
};

export const truncateAddress = (address: string) => {
  return `${address.slice(0, 8)}…${address.slice(-6)}`;
};

export const PROD_WALLET = "0xBdd87538521123603205C2D14cF566851f244983";
export const STAGING_WALLET = "0xaD6267A3de9d9B8B846FCA8b9C51A5b603f4dfc6";

export const LGT_ADMIN_WALLET = {
  [NETWORK.ETHEREUM]: PROD_WALLET,
  [NETWORK.ETHEREUM_SEPOLIA]: STAGING_WALLET,
  [NETWORK.POLYGON]: PROD_WALLET,
  [NETWORK.POLYGON_AMOY]: STAGING_WALLET,
  [NETWORK.AVALANCHE]: PROD_WALLET,
  [NETWORK.AVALANCHE_FUJI]: STAGING_WALLET,
  [NETWORK.ARBITRUM_ONE]: PROD_WALLET,
  [NETWORK.ARBITRUM_ONE_SEPOLIA]: STAGING_WALLET,
  [NETWORK.ARBITRUM_NOVA]: PROD_WALLET,
  [NETWORK.LAMINA1]: PROD_WALLET,
  [NETWORK.LAMINA1_FUJI]: STAGING_WALLET,
  [NETWORK.BASE]: PROD_WALLET,
  [NETWORK.BASE_SEPOLIA]: STAGING_WALLET,
};

export const getLgtWalletByChainId = (chainId: string | NETWORK) => {
  return LGT_ADMIN_WALLET[Number(chainId) as NETWORK];
};

const prodEndpointUrl = "https://api.legitimate.tech/";
const testEndpointUrl = "https://dev-api.legitimate.tech/";

export const getEndpointUrlByChainId = (chainId: NETWORK, route: string) => {
  return `${TESTNET_CHAIN_IDS.has(chainId) ? testEndpointUrl : prodEndpointUrl}${route}`;
};

export const chainHasSimplehash = (chainId: NETWORK) => {
  return !!CHAIN_ID_TO_SIMPLE_HASH_NETWORK[chainId];
};
