import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { DisplayProvider } from "context/DisplayContext";

import VerificationPage from "pages/verification";
import ErrorPage from "pages/error";
import SharePage from "pages/share";

import { inputGlobalStyles } from "styles/global";
import { darkTheme } from "styles/theme";

import i18next from "./i18n";
import { I18nextProvider } from "react-i18next";

import DesktopExperience from "components/DesktopExperience";
import useMobile from "hooks/useMobile";
import AppClipPage from "pages/appclip";
import Loading from "components/Loading";

const BuilderPage = React.lazy(() => import("pages/b"));

const App = () => {
  const isMobile = useMobile();
  const searchParams = new URLSearchParams(window.location.search);
  const uid = searchParams.get("uid");
  const cmac = searchParams.get("cmac");
  const ctr = searchParams.get("ctr");

  if (!isMobile) {
    return <DesktopExperience />;
  }

  return (
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <React.Suspense fallback={<Loading />}>
          <QueryClientProvider client={new QueryClient()}>
            <DisplayProvider>
              <BrowserRouter>
                <Routes>
                  {uid && cmac && ctr && <Route index element={<VerificationPage />} />}
                  {uid && <Route path="/share" element={<SharePage />} />}
                  <Route path="/error" element={<ErrorPage />} />
                  <Route path="/appclip" element={<AppClipPage />} />
                  <Route
                    path="/b/:builderAPIKey/:builderPath"
                    element={<BuilderPage />}
                  />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </BrowserRouter>
            </DisplayProvider>
            {inputGlobalStyles}
          </QueryClientProvider>
        </React.Suspense>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default App;
