import React from "react";

interface DisplayState {
  claimed: boolean;
  reset: boolean;
  owner?: string;
  addressModalOpen: boolean;
  showClaimed: boolean;
  consentModalOpen: boolean;
  ownerName?: string;
}

interface DisplayContextValue {
  state: DisplayState;
  dispatch: React.Dispatch<Partial<DisplayState>>;
}

// This contains a master-switch of sorts for the display state of the tag
// metadata view. It contains and exposes a number of boolean (or other)
// values that control what the main content page looks like. The reducer
// structure future-proofs it, removing the need for mutliple `useState` calls.

export const DisplayContext = React.createContext<DisplayContextValue | null>(
  null,
);

interface DisplayProviderProps {
  children: React.ReactNode;
}

type DisplayReducer = (
  state: DisplayState,
  next: Partial<DisplayState>,
) => DisplayState;

export const DisplayProvider: React.FC<DisplayProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = React.useReducer<DisplayReducer>(
    (p, n) => ({ ...p, ...n }),
    {
      claimed: false,
      reset: false,
      owner: undefined,
      addressModalOpen: false,
      showClaimed: false,
      consentModalOpen: false,
      ownerName: undefined,
    },
  );

  return (
    <DisplayContext.Provider value={{ state, dispatch }}>
      {children}
    </DisplayContext.Provider>
  );
};
