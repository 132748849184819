import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (import.meta.env.VITE_API_STAGE === "production" || import.meta.env.VITE_API_STAGE === "staging") {
  Sentry.init({
    dsn: "https://db18d077b700a49144fa9ae16462c10e@o4505353971564544.ingest.us.sentry.io/4508120567644160",
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          window.location.origin,
          "api.legitimate.tech",
          "dev-api.legitimate.tech",
          "metadata-api.legitimate.tech",
          "dev-metadata-api.legitimate.tech",
        ],
      }),
    ],
    environment: import.meta.env.VITE_API_STAGE,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
