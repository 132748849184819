import { Box, ThemeProvider, Typography } from "@mui/material";
import Wordmark from "assets/images/wordmark.svg?react";
import { useTranslation } from "react-i18next";
import { darkTheme } from "styles/theme";

const DesktopExperience = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{ display: "flex", flexDirection: "column",
          alignItems: "center", backgroundColor: 'white',
        }}
      >
        <Box
          sx={{
            backgroundColor: "#000000",
            marginBottom: 2,
            color: "#FFFFFF",
            padding: 2,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Wordmark height={24} style={{ alignSelf: "center" }} />
          <Typography variant="caption" sx={{ textAlign: "center", mt: 2 }}>
            {t(
              "desktop-experience.description.for-mobile",
              "You are viewing a desktop simulation of an experience designed for mobile.",
            )}
          </Typography>
          <Typography variant="caption" sx={{ textAlign: "center" }}>
            {t(
              "desktop-experience.description.open-on-mobile",
              "Please open this page in a mobile browser for the best experience.",
            )}
          </Typography>
        </Box>
        <iframe
          src={window.location.href}
          title="Verify | Legitimate"
          width={390}
          height={844}
          style={{ border: "none", borderRadius: 12, margin: 12 }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default DesktopExperience;
