import { useSearchParams } from "react-router-dom";

const useTagParams = () => {
  const [searchParams] = useSearchParams();

  const uid = searchParams.get("uid");
  const ctr = searchParams.get("ctr");
  const cmac = searchParams.get("cmac");

  return { uid, ctr, cmac };
};

export default useTagParams;
