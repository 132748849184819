import { Box, styled } from "@mui/material";
import { LGT_IPFS_GATEWAY_URL } from "utils/constants";
import {
  getCloudflareIpfsGatewayURL,
  getLgtIpfsGatewayURL,
  getSourceSet,
} from "utils/image";

interface NftImageProps {
  src: string;
  alt?: string;
}

const NftImage: React.FC<NftImageProps> = ({ src, ...props }) => {
  // sanity check for inconsistent backend data and other third party APIs
  if (!src) {
    return null;
  }

  const isLgtPinata = src.includes(LGT_IPFS_GATEWAY_URL);
  const isIpfsProtocol = src.includes("ipfs://");

  return (
    <ImageContainer>
      <picture>
        {isLgtPinata && <source srcSet={getSourceSet(src)} />}
        <img
          src={
            isIpfsProtocol
              ? getCloudflareIpfsGatewayURL(src)
              : getLgtIpfsGatewayURL(src)
          }
          style={{ objectFit: "cover", width: "100%" }}
          {...props}
        />
      </picture>
    </ImageContainer>
  );
};

const ImageContainer = styled(Box)`
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  margin-bottom: 16px;

  img {
    border-radius: 5px;
  }
`;

export default NftImage;
